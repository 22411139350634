import React from "react"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import useSecureNavigate from "../../CustomHooks/useSecureNavigate";
import {
    Alert,
    AlertTitle,
    Button,
    Grid,
    Modal,
    Typography,
} from "@mui/material";
import { connect } from "react-redux";
import { unAssignedErrorsAction } from '../../../Redux/Actions/Widgets/unAssignedErrorsAction';

const UnAssignedErrors = (props) => {
    const [data, setData] = React.useState('');
    const [selectedAssigned, setSelectedAssigned] = React.useState(true);
    const [selectedValue, setSelectedValue] = React.useState('');
    const secureNavigate = useSecureNavigate();
    const { unassigned_errors: { payerTrends =[]}  } = props;
    React.useEffect(() => {
        props.getUnAssignedErrors();
    }, [])

    React.useEffect(() => {
                  setData(payerTrends);
            setSelectedValue(payerTrends[0]?.payerName);
            setSelectedAssigned(payerTrends[0]?.unassignedErrors)
        }, [props?.unassigned_errors || []])

    const handleChange = (event) => {
        const selectedName = event.target.value;
        setSelectedValue(selectedName);
        const selectedItem = data.find((item) => item.payerName === selectedName);
        if (selectedItem) {
            setSelectedAssigned(selectedItem.unassignedErrors);
        }
    };

    const handleButtonClick = () => {
        secureNavigate('/results', { state: { selectedValue } });
    };
    return (<>
        <div className="widgeterr" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', flexWrap: 'nowrap' }}>
            <Typography style={{ marginBottom: '1rem', flex: '1' }}>
                {selectedAssigned} fallouts to be addressed
            </Typography>
            <Alert severity="error" style={{ marginLeft: '1rem', flexShrink: 0 }}>
                {selectedAssigned}
            </Alert>
        </div>
        <Typography
            align="left"
            gutterBottom="true"
            sx={{ marginTop: '10px', fontSize: '15px' }}
        >Select by Payer</Typography>
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Payers</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedValue}
                    label="Payers"
                    onChange={handleChange}
                    size="small"
                >
                    {data?.length > 0 && data?.map((item) => (
                        <MenuItem key={item.payerName} value={item.payerName}>
                            {item.payerName}
                        </MenuItem>
                    ))}

                </Select>
            </FormControl>
        </Box>
        <Grid item xs={12} className="eligibility-error-btn" style={{ marginTop: '1rem' }}>
            <Button className={`view-all-error`} variant="contained" color="success" onClick={handleButtonClick}>
                Assign fallouts
            </Button>
        </Grid>
    </>)
}

const mapStateToProps = (state) => {
    return { ...state };
};

const mapActionToProps = {
    getUnAssignedErrors: unAssignedErrorsAction,

};

export default connect(mapStateToProps, mapActionToProps)(UnAssignedErrors);