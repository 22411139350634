import { FETCHING_DATA, GRID_SAVING } from '../../../Constants/actionTypes';
import APIS from '../../../Constants/apiConstant';
import { apiAction } from '../API/apiAction';

export const aggridSavingAction = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === GRID_SAVING),
      },
    });
    await apiAction(dispatch, GRID_SAVING, payload);
  };
};
