import { GET_ALL_TABLE } from '../../../Constants/actionTypes';

export const getAllTablesReducer = (state = null, action) => {
  switch (action.type) {
    case GET_ALL_TABLE:
      return action?.payload || [];
    default:
      return state;
  }
};
