import { ASSIGNED_ERRORS_PROGRESS } from '../../../Constants/actionTypes';

export const assignedErrorsProgressReducer = (state = null, action) => {
  switch (action.type) {
    case ASSIGNED_ERRORS_PROGRESS:
      return action.payload;
    default:
      return state;
  }
};
