import { FILE_STATUS } from '../../../Constants/ComponentConstants/WidgetConstants/PayerEligibilityFileTrackingConstants/fileTrackingConstants';
const { REJECTED, COMPLETED, PROCESSING } = FILE_STATUS;
export const getStatusClassName = (str = '') => {
  switch (str) {
    case REJECTED?.TEXT:
      return REJECTED?.CLASS;
    case COMPLETED?.TEXT:
      return COMPLETED?.CLASS;
    case PROCESSING?.TEXT:
      return PROCESSING?.CLASS;
    default:
      return str;
  }
};

export const getStatus = (rowData = [], key = '') => {
  return rowData?.find(({ payerName, status }) => payerName === key && status)
    ?.status;
};

export const fileTrackingColumnDef = ({
  AG_COLUMN_CENTER,
  FileStatusRender,
  GotoFileDetails,
  records,
  goToFile,
  INSERT_DRIVE_FILE_OUTLINED_ICON,
  CURSOR_POINTER,
}) => {
  return [
    {
      headerName: 'Payer Name',
      field: 'payerName',
      showRowGroup: true,
      cellRenderer: 'agGroupCellRenderer',
      minWidth: 400,
      cellClass: 'grid-cell-center',
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 150,
      ...AG_COLUMN_CENTER,
      cellRenderer: FileStatusRender,
      cellRendererParams: {
        rowData: records,
      },
    },
    {
      headerName: 'Payer Name',
      field: 'payerName',
      rowGroup: true,
      minWidth: 250,
      hide: true,
    },
    { headerName: 'Date', field: 'date', ...AG_COLUMN_CENTER },
    { headerName: 'File In', field: 'fileIn', ...AG_COLUMN_CENTER },
    { headerName: 'File Out', field: 'fileOut', ...AG_COLUMN_CENTER },
    {
      headerName: 'Records in Input Files',
      field: 'recordsInInputFiles',
      minWidth: 250,
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'Records rejected by Gateway',
      field: 'recordsRejectedByGateway',
      minWidth: 250,
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'Records errored at DQM',
      field: 'recordsErroredatDqm',
      minWidth: 250,
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'Records errored by Membership',
      field: 'recordsErroredByMembership',
      minWidth: 250,
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'Records errored by Enrollment',
      field: 'recordsErroredByEnrollment',
      minWidth: 250,
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'Records errored by Person',
      field: 'recordsErroredByPerson',
      minWidth: 250,
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'Records errored by entities',
      field: 'recordsRejectedByEntities',
      minWidth: 250,
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'Records Processed',
      field: 'recordsProcessed',
      minWidth: 250,
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'Records Success Count by Enrollment',
      field: 'recordsSuccessCountByEnrollment',
      minWidth: 250,
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'Records Success Count by Membership',
      field: 'recordsSuccessCountByMembership',
      minWidth: 250,
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'Records Success Count by Person',
      field: 'recordsSuccessCountByPerson',
      minWidth: 250,
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'File link',
      field: 'fileLink',
      ...AG_COLUMN_CENTER,
      cellRenderer: GotoFileDetails,
      cellRendererParams: {
        goToFile: goToFile,
        icon: INSERT_DRIVE_FILE_OUTLINED_ICON,
        fileClass: CURSOR_POINTER,
      },
    },
  ];
};

export const tableColumnOrder = (
  { get_all_table = {}, componentName = '', payerEligibilityTableId = '' },
  table = [],
) => {
  const savedData = get_all_table?.layout?.widgets
    ?.find(({ name }) => name === componentName)
    ?.screens[0]?.tables?.find(
      ({ tableId }) => tableId === payerEligibilityTableId,
    );
  if (savedData) {
    const { columnOrder = [], columnVisibility = {}, sorting = [] } = savedData;
    const getTableLayout = columnOrder?.reduce((accu, col) => {
      if (columnVisibility[col]) {
        const viewedColns = table
          ?.filter(({ field }) => field === col)
          ?.map((filteredRow) => {
            const { field } = filteredRow;
            const sortingData = sorting.find(({ colId }) => colId === field);
            if (sortingData) filteredRow['sort'] = sortingData.sort;
            return filteredRow;
          });
        return [...accu, ...viewedColns];
      } else return accu;
    }, []);
    return { table: getTableLayout, layOut: savedData };
  } else return { table: table, layOut: {} };
};

export const createPayload = ({
  columnDefs = [],
  savedPayload = {},
  componentName = '',
  payerEligibilityTableId = '',
  allColumnState = [],
}) => {
  const payload = {
    screenName: componentName,
    tableId: payerEligibilityTableId,
    updates: {
      columnOrder: [],
      columnVisibility: savedPayload?.columnVisibility,
      filtering: savedPayload?.filtering,
      sorting: [],
    },
  };
  allColumnState?.forEach((element) => {
    const { colId, sort } = element;
    const colmns = columnDefs.find(({ field }) => field === colId);
    if (colmns) {
      if (!payload.updates.columnOrder.includes(colId))
        payload.updates.columnOrder.push(colId);
      if (sort && !payload.updates.sorting.find((elm) => elm.colId === colId))
        payload.updates.sorting.push({ colId, sort });
    }
  });
  return payload;
};
