import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { selectedUserReducer } from './Reducers/Membership/selectedUserReducer';

import { apiReducer } from './Reducers/apiReducer';
import { membershipReducer } from './Reducers/Membership/membershipReducer';
import { tokenGenerationReducer } from './Reducers/tokenGenerationReducer';
import { membershipSearchReducer } from './Reducers/Membership/membershipSearchReducer';
import { inputReducer } from './Reducers/inputReducer';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { dashboardSettingsReducer } from './Reducers/Dashboard/dashboardSettingsReducer';
import { oktaTokenReducer } from './Reducers/oktaTokenReducer';
import { errorTrendsMTDReducer } from './Reducers/Widgets/errorTrendsMTDReducer';
import { eligibilityErrorsMTDReducer } from './Reducers/Widgets/eligibilityErrorsMTDReducer';
import { eligibilityErrorsRejMTDReducer } from './Reducers/Widgets/eligibilityErrorsRejMTDReducer';
import { assigneeApiReducer } from './Reducers/Errorflow/assigneeApiReducer';
import { teamMembersReducer } from './Reducers/Errorflow/teamMembersReducer';
import { markAsCompleteReducer } from './Reducers/Errorflow/markAsCompleteReducer';
import { errorResultsMTDReducer } from './Reducers/Errorflow/errorResultsMTDReducer';
import { reAssignAllToMeReducer } from './Reducers/Errorflow/reAssignAllToMeReducer';
import { payerErrorFileRatioMTDReducer } from './Reducers/Widgets/payerErrorFileRatioMTDReducer';
import { unAssignedErrorsReducer } from './Reducers/Widgets/unAssignedErrorsReducer';
import { assignedErrorsProgressReducer } from './Reducers/Widgets/assignedErrorsProgressReducer';
import { aggridSavingReducer } from './Reducers/Membership/aggridSavingReducer';
import { aggridSavingRed } from './Reducers/Membership/aggridSaving';
import {
  getAdminReducer,
  getTPAReducer,
  getUserTPAReducer,
  getTpaIdsReducer,
} from './Reducers/Admin/getAdminTPAReducer';
import { membershipProductReducer } from './Reducers/Membership/getMembershipProductReducer';
import { checkUserIsAdminReducer } from './Reducers/Admin/checkUserIsAdminReducer';
import { eligibilityErrorResultsReducer } from './Reducers/Errorflow/eligibilityErrorResultsReducer';
import { eligibilityErrorTrendsReducer } from './Reducers/Errorflow/eligibilityErrorTrendsReducer';
import {
  fileTrackingWidgetReducer,
  fileTrackingReducer,
  fileDetailsReducer,
} from './Reducers/Widgets/fileTrackingReducer';
import { getAllTablesReducer } from './Reducers/Common/getAllTablesReducer';

const middleware = [thunk];
const enhancers = [applyMiddleware(...middleware)];
const composedEnhancer = compose(...enhancers);

const persistConfig = {
  key: 'root',
  storage,
};

const allReducers = combineReducers({
  selectedUser: selectedUserReducer,
  apiData: apiReducer,
  membership: membershipReducer,
  access_token: tokenGenerationReducer,
  member_list: membershipSearchReducer,
  component_input: inputReducer,
  dashboard_settings: dashboardSettingsReducer,
  okta_token: oktaTokenReducer,
  admin_user: getAdminReducer,
  get_tpa: getTPAReducer,
  get_user_tpa: getUserTPAReducer,
  mtd_data: errorTrendsMTDReducer,
  eligerr_mtddata: eligibilityErrorsMTDReducer,
  eligerrrej_mtddata: eligibilityErrorsRejMTDReducer,
  payererrratio_mtd: payerErrorFileRatioMTDReducer,
  unassigned_errors: unAssignedErrorsReducer,
  assigned_errors_progress: assignedErrorsProgressReducer,
  member_product: membershipProductReducer,
  check_admin: checkUserIsAdminReducer,
  eligerror_result: eligibilityErrorResultsReducer,
  file_tracking_widget: fileTrackingWidgetReducer,
  file_tracking: fileTrackingReducer,
  file_details: fileDetailsReducer,
  get_tpa_ids: getTpaIdsReducer,
  assignee: assigneeApiReducer,
  teamMembers: teamMembersReducer,
  mark_as_complete: markAsCompleteReducer,
  error_results_mtd: errorResultsMTDReducer,
  reassign_all_to_me: reAssignAllToMeReducer,
  elig_error_trends: eligibilityErrorTrendsReducer,
  get_all_table: getAllTablesReducer,
  save_grid: aggridSavingReducer,
  get_saved_grid: aggridSavingRed,
});

const persistedReducer = persistReducer(persistConfig, allReducers);

export const store = createStore(
  persistedReducer,
  {
    selectedUser: {},
    apiData: {},
    membership: {},
    access_token: '',
    member_list: [],
    component_input: {},
    dashboard_settings: {},
    okta_token: {},
    admin_user: [],
    get_tpa: [],
    get_user_tpa: [],
    mtd_data: {},
    eligerr_mtddata: {},
    eligerrrej_mtddata: {},
    payererrratio_mtd: {},
    unassigned_errors: {},
    assigned_errors_progress: {},
    member_product: [],
    check_admin: {},
    eligerror_result: {},
    file_tracking_widget: [],
    file_tracking: {},
    file_details: [],
    get_tpa_ids: [],
    assignee: [],
    teamMembers: [],
    mark_as_complete: [],
    error_results_mtd: [],
    reassign_all_to_me: [],
    save_grid: [],
    elig_error_trends: {},
    get_all_table: [],
    get_saved_grid: [],
  },
  composedEnhancer,
);

export const persistor = persistStore(store);
