import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Routes, Route, useNavigate } from 'react-router-dom';
import { ROUTES, DENIED_PATH } from '../Constants/routesConstant';

import { RequiredAuth } from './SecureRoutes';

import Header from './Common/Header';
import Footer from './Common/Footer';
import SubscriberDetails from './Membership/Details/SubscriberDetails';
import Dashboard from './Dashboard/Dashboard';
import CheckAppInactivity from './Common/CheckAppInactivity';
import AdminAndSecurity from './AdminAndSecurity/AdminAndSecurity';


import SearchResults from './Membership/SearchResults/SearchResults';
import Eligibilityerrortrends from './Widget/EligibilityErrorsTrends/EligibilityErrorTrends';
import EligibilityErrorResults from './Widget/MembershipToErrorRatio/EligibilityErrorResults';
import FileTracking from './Widget/PayerEligibilityFileTracking/FileTracking';
import AddNewSubscriber from './Widget/Eligibility Maintenance/AddNewSubscriber';

import InputFileSearchResults from './Widget/PayerFileSearch/InputFileSearchResults';
import ErrorDetails from './Widget/MembershipToErrorRatio/ErrorDetails';
import AccessDenied from './Common/AccessDenied';
import NotFound from './Common/NotFound';

const BACKEND_URL = process.env.REACT_APP_BACKEND;
const IS_LOCAL = process.env.REACT_APP_ENV === 'local';

const AppRoutes = () => {
  const [authenticated, setAuthenticated] = useState(IS_LOCAL ? true : false);
  const [loading, setLoading] = useState(IS_LOCAL ? false : true);

  if (!IS_LOCAL) {
    useEffect(() => {
      axios.get(`${BACKEND_URL}/authenticate`, {
        withCredentials: true,
      }).then(response => {
        if (response.status === 200) {
          setAuthenticated(true);
        } else {
          window.location.href = `${BACKEND_URL}/loginInitiate`
        }
      }).catch(error => {
        console.error('Authentication check failed: ', error);
        window.location.href = `${BACKEND_URL}/loginInitiate`
      }).finally(() => {
        setLoading(false);
      })
    }, []);

    useEffect(() => {
      axios.get(`${BACKEND_URL}/authenticate`, {
        withCredentials: true,
      }).then(response => {
        if (response.status === 200) {
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
          window.location.href = `${BACKEND_URL}/loginInitiate`
        }
      }).catch(error => {
        setAuthenticated(false);
        console.error('Authentication check failed: ', error);
        window.location.href = `${BACKEND_URL}/loginInitiate`;
      })
    }, [location.pathname]);
  }

  const {
    DASHBOARD,
    SUBSCRIBER,
    ADMIN_SECURITY,
    ERROR_TRENDS,
    ERROR_RESULTS,
    MY_ERROR_RESULTS,
    FILE_TRACKING,
    NOT_FOUND,
    ADD_NEW_SUBSCRIBER,
    ACCESS_DENIED,
    FILE_SEARCH,
    ERROR_DETAILS
  } = ROUTES;

  if (loading) {
    return <div>Loading auth status</div>;
  }

  if (!IS_LOCAL && !authenticated) {
    return <div>Redirecting to login</div>
  }
  return (
    <div className="App">
      <Header />
      <CheckAppInactivity />
      <Routes>
        <Route path={DASHBOARD} element={!DENIED_PATH.includes(DASHBOARD) ? <Dashboard /> : <AccessDenied />} />
        <Route path={ROUTES.ADD_NEW_SUBSCRIBER} element={!DENIED_PATH.includes(ADD_NEW_SUBSCRIBER) ? <AddNewSubscriber /> : <AccessDenied />} />
        <Route
          path={SUBSCRIBER.SUBSCRIBER_DETAILS}
          element={!DENIED_PATH.includes(SUBSCRIBER.SUBSCRIBER_DETAILS) ? <SubscriberDetails /> : <AccessDenied />}
        />
        <Route
          path={SUBSCRIBER.DEPENDENT_DETAILS}
          element={!DENIED_PATH.includes(SUBSCRIBER.DEPENDENT_DETAILS) ? <SubscriberDetails /> : <AccessDenied />}
        />
        <Route
          path={SUBSCRIBER.SEARCH_RESULT}
          element={!DENIED_PATH.includes(SUBSCRIBER.SEARCH_RESULT) ? <SearchResults /> : <AccessDenied />}
        />
        <Route
          path={ADMIN_SECURITY}
          element={!DENIED_PATH.includes(ADMIN_SECURITY) ? <AdminAndSecurity /> : <AccessDenied />} />
        <Route
          path={ERROR_TRENDS}
          element={!DENIED_PATH.includes(ERROR_TRENDS) ? <Eligibilityerrortrends /> : <AccessDenied />}
        />
        <Route
          path={MY_ERROR_RESULTS}
          element={!DENIED_PATH.includes(MY_ERROR_RESULTS) ? <EligibilityErrorResults /> : <AccessDenied />}
        />
        <Route
          path={ERROR_RESULTS}
          element={!DENIED_PATH.includes(ERROR_RESULTS) ? <EligibilityErrorResults /> : <AccessDenied />}
        />
        <Route
          path={FILE_SEARCH}
          element={!DENIED_PATH.includes(FILE_SEARCH) ? <InputFileSearchResults /> : <AccessDenied />}
        />
        <Route
          path={FILE_TRACKING}
          element={!DENIED_PATH.includes(FILE_TRACKING) ? <FileTracking /> : <AccessDenied />}
        />
        <Route
          path={ERROR_DETAILS}
          element={!DENIED_PATH.includes(ERROR_DETAILS) ? <ErrorDetails /> : <AccessDenied />}
        />
        <Route
          path={NOT_FOUND}
          element={<NotFound />}
        />
        <Route
          path={ACCESS_DENIED}
          element={<AccessDenied />}
        />
      </Routes>
      <Footer />
    </div>
  );
};

export default AppRoutes;