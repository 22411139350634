import { FETCHING_DATA, TEAM_MEMBERS } from '../../../Constants/actionTypes';
import APIS from '../../../Constants/apiConstant';
import { apiAction } from '../API/apiAction';

export const teamMembersAction = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === TEAM_MEMBERS),
      },
    });

    await apiAction(dispatch, TEAM_MEMBERS, payload);
  };
};
