import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { connect } from "react-redux";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useLocation } from 'react-router-dom';
import useSecureNavigate from '../../CustomHooks/useSecureNavigate';
import * as XLSX from 'xlsx';
import Icon from "../../Common/Icon"
import IconButton from '@mui/material/IconButton';
import { emptyObject } from "../../../Utils/Common/common.util"
import { membershipSearchAction } from "../../../Redux/Actions/Membership/memberSearchAction"
import { selectedUserAction } from "../../../Redux/Actions/Membership/selectedUserAction"
import { ROUTES } from "../../../Constants/routesConstant"
import { Box } from '@mui/material';
import { PRIMARY_PERSON_NUMBER } from "../../../Constants/ComponentConstants/MembershipConstants/membershipConstants"
import { aggridSavingAction } from '../../../Redux/Actions/Membership/aggridSavingAction';
import { aggridSavingActionGet } from '../../../Redux/Actions/Membership/aggridSaving';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const MembershipSearchResults = (props) => {
  const [data, setData] = useState([])
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [showColumnMenu, setShowColumnMenu] = useState(false);
  const [visibilityStrings, setVisibilityStrings] = useState([]);
  const secureNavigate = useSecureNavigate();
  const gridRef = useRef(null);
  const gridApiRef = useRef(null);
  const columnMenuRef = useRef(null);
  const getData = (data) => {
    if (data?.data?.personNumber === PRIMARY_PERSON_NUMBER) {
      props.doSetUser({ ...data.data, ...(data.data.memberId && { membershipId: data.data.memberId }) })
      navigate(`${ROUTES.SUBSCRIBER.SUBSCRIBER_DETAILS}`)
    }
    else {
      props.doSetUser({
        ...data.data,
        ...(data.data.resourceId && { memberResourceId: data.data.resourceId }),
        ...(data.data.realtiveId && { dependentRelativeId: data.data.realtiveId })
      })
      secureNavigate(`${ROUTES.SUBSCRIBER.DEPENDENT_DETAILS}`)
    }
  }
  const handleDGCNavigation = (params) => {
    const dgcUrlEN = process.env.REACT_APP_DGC_URL;
    window.open(dgcUrlEN, '_blank');

  };
  const CustomButtonComponent = (idparam, params) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button onClick={() => getData(idparam)} style={{ color: 'blue', border: 'none', cursor: 'pointer', marginRight: '1rem', background: 'transparent' }}>View</button>
        <button
          style={{ color: 'blue', border: 'none', cursor: 'pointer', background: 'transparent' }}
          onClick={() => handleDGCNavigation(idparam)}
        >
          Edit
        </button>
      </div>
    )
  }
  const [columnDefs, setColumnDefs] = useState([
    { headerName: 'Actions', field: 'Button', cellRenderer: CustomButtonComponent, flex: 1, filter: false, sortable: false },
    {
      headerName: 'Payer Org ID', field: 'payerId', sortable: true, filter: 'agSetColumnFilter', order: 0, filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
        filterParams: {
          applyMiniFilterWhileTyping: true,
        },
      }
    },

    {
      headerName: 'Cigna Group ID', field: 'cignaGroupId', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: ' Internal Group Id', field: 'groupId', sortable: true, order: 1, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
        filterParams: {
          applyMiniFilterWhileTyping: true,
        },
      }
    },
    {
      headerName: 'Membership ID', field: 'memberId', sortable: true, order: 6, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },

    // {
    //   headerName: 'Middle Initial', field: 'middleInitial', sortable: true, order: 3, sort: 'asc', filter: 'agSetColumnFilter',
    //   filterParams: {
    //     applyMiniFilterWhileTyping: true,
    //     suppressMiniFilter: false,
    //     selectAllowMiniFilter: true,
    //     closeOnApply: true,
    //     buttons: ['reset', 'apply'],
    //   }
    // },
    // {
    //   headerName: 'Suffix', field: 'middleInitial', sortable: true, order: 3, sort: 'asc', filter: 'agSetColumnFilter',
    //   filterParams: {
    //     applyMiniFilterWhileTyping: true,
    //     suppressMiniFilter: false,
    //     selectAllowMiniFilter: true,
    //     closeOnApply: true,
    //     buttons: ['reset', 'apply'],
    //   }
    // },
    {
      headerName: 'Person Number', field: 'personNumber', sortable: true, order: 5, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'SSN', field: 'SSN', sortable: true, order: 4, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'First Name', field: 'firstName', sortable: true, order: 2, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'Last Name', field: 'lastName', sortable: true, order: 3, sort: 'asc', filter: 'agSetColumnFilter',
      filterParams: {
        applyMiniFilterWhileTyping: true,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        closeOnApply: true,
        buttons: ['reset', 'apply'],
      }
    },
    {
      headerName: 'Date of Birth', field: 'birthday', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'Address', field: 'address', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'State', field: 'state', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'City', field: 'city', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'Zipcode', field: 'zipcode', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'Participant Type', field: 'participantType', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },

    // {
    //   headerName: 'TpaName', field: 'tpaName', sortable: true, filter: 'agSetColumnFilter',
    //   filterParams: {
    //     debounceMs: 500,
    //     suppressMiniFilter: false,
    //     selectAllowMiniFilter: true,
    //     buttons: ['reset', 'apply'],
    //     closeOnApply: true,
    //   }
    // },

    {
      headerName: 'Gender', field: 'gender', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },

    {
      headerName: 'Eligibility Effective Date', field: 'Elg_eff_date', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'Eligibility Termination Date', field: 'elg_ter_date', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'Eligibility Status', field: 'elg_status', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },


  ]);

  React.useEffect(() => {
    if (props?.component_input?.search && !emptyObject(props?.component_input?.search)) {
      const { firstName, lastName, birthday, postalCode, state, personNumber, city, gender, membershipId, groupId } = props?.component_input?.search;
      const searchQuery = {};
      if (firstName) {
        searchQuery['firstName:startsWith'] = firstName;
      }
      if (lastName) {
        searchQuery['lastName:startsWith'] = lastName;
      }
      if (groupId) {
        searchQuery['groupId'] = groupId;
      }
      if (birthday) {
        searchQuery['birthday'] = birthday;
      }
      if (postalCode) {
        searchQuery['postalCode'] = postalCode;
      }
      if (state) {
        searchQuery['state'] = state;
      }
      if (membershipId) {
        searchQuery['membershipId'] = membershipId;
      }
      if (personNumber) {
        searchQuery['personNumber'] = personNumber;
      }
      if (city) {
        searchQuery['city'] = city;
      }
      if (gender) {
        searchQuery['gender'] = gender;
      }
      props.getSearchData(searchQuery)
    }
    else secureNavigate("/")
    return () => {
      setData([])
    };
  }, [props?.component_input?.search])
  const handleApply = () => {
    setShowColumnMenu(false);
  };
  const handleReset = () => {
    const updatedColumns = columnDefs.map(column => ({
      ...column,
      hide: false
    }));
    setColumnDefs(updatedColumns);
    setShowColumnMenu(false);
  };
  useEffect(() => {
    if (gridApi && gridColumnApi) {
      gridColumnApi.getAllColumns().forEach((column) => {
      });
    }
    function handleClickOutside(event) {
      if (columnMenuRef.current && !columnMenuRef.current.contains(event.target)) {
        setShowColumnMenu(false);
      }
    }
    setData(props?.member_list?.error ? [] : props?.member_list)
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [gridApi, gridColumnApi]);
  const [columnOrder, setColumnOrder] = useState(columnDefs.map(col => col?.field));

  useEffect(() => {
    props.getSavedgrid();
  }, [])

  useEffect(() => {
    const savedColumnOrder = props.get_saved_grid?.layout?.widgets
      ?.find(({ name }) => name === "EligibilitySearch")
      ?.screens[0]?.tables?.find(
        ({ tableId }) => tableId === "eligibilitysearch",
      ).columnOrder;
    if (savedColumnOrder) {
      const newColumnDefs = savedColumnOrder.map(field =>
        columnDefs.find(colDef => colDef.field === field)
      );
      setColumnDefs(newColumnDefs);
      setColumnOrder(savedColumnOrder);
    }
  }, [props.get_saved_grid])


  const onColumnMoved = async (params) => {
    gridRef.current = params.api
    if (gridRef.current) {
      const allColumnState = gridRef.current.getColumnState();
      const screenPayload = () => {
        const payload = {
          screenName: "EligibilitySearch",
          tableId: "eligibilitysearch",
          updates: {
            columnOrder: [],
          },
        };
        allColumnState?.forEach((element) => {
          const { colId } = element;
          const colmns = columnDefs.find(({ field }) => field === colId);
          if (colmns) {
            if (!payload.updates.columnOrder.includes(colId))
              payload.updates.columnOrder.push(colId);
          }
        });
        return payload;
      };
      const payload = screenPayload();
      await props.saveAggrid(payload)
    }
  };

  const toggleColumnMenu = () => {
    setShowColumnMenu(!showColumnMenu);
  };
  const toggleColumn = (columnName, checked) => {
    const newColumnDefs = columnDefs.map(column => {
      if (column.field === columnName) {
        return { ...column, hide: !checked };
      }
      return column;
    });
    setColumnDefs(newColumnDefs);
    const visibleColumns = newColumnDefs.filter((column) => !column.hide).map((column) => column.field);
    const visibilityStrings = newColumnDefs.map((column) => {
      if (column && column.field !== undefined) {
        return `${column.field}:${!column.hide}`;
      }
      return null;
    }).filter(Boolean);
    setVisibilityStrings(visibilityStrings);
  };
  const gridOptions = {
    suppressBorders: true,
    defaultColDef: {
      minWidth: 140,
      cellStyle: {
        fontSize: '12px',
      },
    },
  };
  const exportDataGridToExcel = (columnDefs, data, fileName) => {
    const headers = columnDefs.filter(col => col.field !== 'Button').map(col => col.headerName);
    const mappedData = data.map(row => {
      let mappedRow = {};
      columnDefs.forEach(col => {
        if (col.field !== 'Button') {
          mappedRow[col.headerName] = row[col.field]
        }
      });
      return mappedRow;
    });
    const worksheet = XLSX.utils.json_to_sheet(mappedData, { header: headers });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  };
  const handleExportExcel = () => {
    const rowModel = gridApi.getModel();
    const filteredRows = [];
    rowModel.rowsToDisplay.forEach(node => {
      if (node.displayed) {

        filteredRows.push(node.data);
      }
    });
    const visibleColumns = columnDefs.filter(col => !col.hide);
    const visibleColumnFields = visibleColumns.map(col => col.field);
    const filteredData = filteredRows.map(row => {
      const filteredRow = {};
      visibleColumnFields.forEach(field => {
        if (field !== 'Button') {
          filteredRow[field] = row[field];
        }
      });
      return filteredRow;
    })
    exportDataGridToExcel(visibleColumns, filteredData, 'SearchResults.xlsx');
  };
  const content = "Download the results as an excel file";
  return (
    <>
      <h1 className="searchtitle">Eligibility Search Results</h1>
      <div className="line"></div>
      <Box >
        <div className="ag-theme-alpine" style={{ minHeight: '100vh', width: '100%', padding: '40px', marginTop: '5px' }}>
          <div >
            <div style={{ right: '45px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <button
                style={{
                  border: '1px solid #035c67',
                  backgroundColor: 'white',
                  padding: '5px 10px',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  marginbottom: '10px',
                  left: '10px',
                  color: ' #035c67',
                  width: '150px'
                }}
                onClick={toggleColumnMenu}
              >
                Edit Columns
      </button>
              <IconButton onClick={handleExportExcel} style={{ color: 'blue', fontSize: 15, border: 'none', outline: 'none', background: 'none', borderRadius: 0 }}>
                <p>Download Results </p>
                <Icon name="ArrowDownwardIcon" />
              </IconButton>
            </div>
          </div>
          {showColumnMenu && (
            <div
              style={{
                position: 'absolute',
                top: '243px',
                left: '12px',
                backgroundColor: '#fff',
                border: '1px solid #ccc',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                padding: '10px',
                zIndex: '999',
                margin: '35px'
              }}
            >
              <strong> Columns</strong>
              {columnDefs.map((column) => (
                <div key={column.field} style={{ marginTop: '5px' }}>
                  <label style={{ cursor: 'pointer' }}>
                    <input
                      type="checkbox"
                      checked={!column.hide}
                      onChange={(e) => toggleColumn(column.field, e.target.checked)}
                      disabled={column.headerName === 'Actions'}
                      style={{
                        marginRight: '15px', appearence: 'none',
                        backgroundColor: '#035c67',
                        width: '14px',
                        height: '14px',
                        borderRadius: '3px',
                        cursor: 'pointer',
                        colour: '#035c67',
                        top: '12px'
                      }}
                    />
                    {column.headerName}
                  </label>
                </div>
              ))}
              <div style={{ marginTop: '10px' }}>
                <button onClick={handleReset} style={{ marginRight: '10px', border: 'none', color: 'blue', backgroundColor: 'white', cursor: 'pointer' }} >Reset</button>
                <button onClick={handleApply} style={{ marginRight: '10px', backgroundColor: '#035c67', color: 'white', border: 'none', cursor: 'pointer' }}> Apply </button>
              </div>
            </div>
          )}
          <AgGridReact
            domLayout="normal"
            rowData={data}
            columnDefs={columnDefs}
            gridOptions={gridOptions}
            suppressDragLeaveHidesColumns={true}
            pagination={true}
            paginationPageSize={10}
            paginationPageSizeSelector={[10, 20, 50]}
            domLayout='autoHeight'
            enableColumnHandling
            ref={(grid) => { gridApiRef.current = grid }}
            onColumnMoved={onColumnMoved}
          />
        </div>
      </Box>
    </>
  );
};
const mapStateToProps = (state) => {
  return { ...state };
};

const mapActionToProps = {
  doSetUser: selectedUserAction,
  getSearchData: membershipSearchAction,
  saveAggrid: aggridSavingAction,
  getSavedgrid: aggridSavingActionGet,
};

export default connect(mapStateToProps, mapActionToProps)(MembershipSearchResults);