import React from "react"
import { Grid, Button } from "@mui/material";
import { ROUTES } from "../../../Constants/routesConstant"
import { useNavigate, useLocation } from "react-router-dom";
import useSecureNavigate from "../../CustomHooks/useSecureNavigate";
import { connect } from "react-redux";
import { errorTrendsMTDAction } from '../../../Redux/Actions/Widgets/errorTrendsMTDAction';
import widgetConstant, { MTD_ITEM } from '../../../Constants/widgetConstant';
import { eligibilityErrorTrendsAction } from "../../../Redux/Actions/Errorflow/eligibilityErrorTrendsAction";

const EligibilityErrorsTrends = (props) => {
    const { totalError, getMTDData, mtd_data, getErrorTrendsData } = props;
    const [links, setLinks] = React.useState([]);
    const range = widgetConstant[props.componentName].mtdItem ? widgetConstant[props.componentName].mtdItem : MTD_ITEM[4];
    const rangeStr = String(range);
    const objtoarr = (obj) => {
        if (!obj || !obj.payersPercentages)
            return;
        const tempLink = [];
        Object.keys(obj?.payersPercentages).forEach(elm => {
            if (!tempLink.map(txt => txt.text).includes(elm))
                tempLink.push({ text: elm, value: obj?.payersPercentages[elm] })
        })
        setLinks([...[], ...tempLink])
    };

    React.useEffect(() => {
        objtoarr(mtd_data);
    }, [mtd_data]);

    React.useEffect(() => {
        getMTDData({ range: props.mtdItem === "" ? MTD_ITEM[4] : props.mtdItem })
    }, [props.mtdItem])

    const secureNavigate = useSecureNavigate();
    const location = useLocation();
    const handleViewTrends = (param={}) => {
        secureNavigate(`${ROUTES.ERROR_TRENDS.replace(':range', rangeStr)}`, { ...param, state: { from: location?.pathname } })
        getErrorTrendsData({ range: range })
    };
    return (<div style={{ padding: '1.25rem 0.5rem 0rem 0rem' }}>
       <div style={{ minHeight: '9.375rem' }}>
            <Grid container>
                <Grid id={`current-index-${props.currIndex}`} item style={{ fontWeight: 'bold' }} xs={9}>{totalError}</Grid>
                <Grid item className={`text-center`} style={{ fontWeight: 'bold' }} xs={3}>{mtd_data?.totalErrors}</Grid>
            </Grid>
            {links.map(link => {
                return (
                    <Grid key={JSON.stringify(link)} container>
                        <Grid item className={`error-container widget-anchor common-line`} xs={9}>
                            <a href="#"
                                className={`widget-anchor`} title={link.text} >
                                {link.text}</a>
                        </Grid>
                        <Grid item className={`text-rights error-container common-line`} xs={3} >{link.value}</Grid>
                    </Grid>
                )
            })}
        </div>
        <Grid item xs={12} className="eligibility-error-btn">
            <Button className={`view-all-error`} variant="contained" color="success" size="small" onClick={handleViewTrends}>
                View Trends
                </Button>
        </Grid>
    </div>)
}

const mapStateToProps = (state) => {
    return { ...state };
};

const mapActionToProps = {
    getMTDData: errorTrendsMTDAction,
    getErrorTrendsData: eligibilityErrorTrendsAction,
};

export default connect(mapStateToProps, mapActionToProps)(EligibilityErrorsTrends);