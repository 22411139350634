import React from "react"
import { Grid, Button } from "@mui/material";
import { connect } from "react-redux";
import { assignedErrorsProgessAction } from '../../../Redux/Actions/Widgets/assignedErrorsProgressAction';

const EligibilityErrorsInProgress = (props) => {

    const { assigned_errors_progress: { payerTrends}, getAssignedErrors } = props;
    const [links, setLinks] = React.useState([]);
    const objtoarr = (obj) => {
        const tempLink = [];
        Object.keys(obj).forEach(elm => {
            if (!tempLink.map(txt => txt.text).includes(elm))
                tempLink.push({ text: elm, value: obj[elm] })
        })
        setLinks([...[], ...tempLink])
    };
    React.useEffect(() => {
        getAssignedErrors();
    }, [])

    React.useEffect(() => {
        setLinks(payerTrends);
    }, [])

    return (<>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid item style={{ fontWeight: 'bold', textalign: 'left' }}>Total errors</Grid>
            <Grid item className={`text-right`} style={{ fontWeight: 'bold', paddingBottom: '10px' }}>{links?.length > 0 && links[0]?.assignedErrors}</Grid>
        </Grid>
        {links?.map((link) => {
            return (
                <Grid container key={link.assignedErrors}>
                    <Grid item className={`error-container widget-anchor common-line`} xs={11}>
                        {link.payerName}
                    </Grid>
                    <Grid item className={`text-right error-container common-line`} xs={1}>
                        {link.assignedErrors}
                    </Grid>
                </Grid>
            );
        })}

    </>)

}

const mapStateToProps = (state) => {
    return { ...state };
};

const mapActionToProps = {
    getAssignedErrors: assignedErrorsProgessAction,

};

export default connect(mapStateToProps, mapActionToProps)(EligibilityErrorsInProgress);