export const MEMBER_INFORMATION = [
  {
    headerName: 'SSN',
    field: 'SSN',
    minWidth: 250,
  },
  {
    headerName: 'MemberShip ID',
    field: 'memberId',
    minWidth: 250,
  },
  {
    headerName: 'Person Number',
    field: 'personNumber',
    minWidth: 250,
  },
  {
    headerName: 'RelationShip Code',
    field: 'relationShipCode',
    minWidth: 250,
  },
];
export const MEMBER_INFORMATION1 = [
  {
    headerName: 'First Name',
    field: 'firstName',
    minWidth: 250,
  },
  {
    headerName: 'Last Name',
    field: 'lastName',
    minWidth: 250,
  },
  {
    headerName: 'Date of Birth',
    field: 'birthDate',
    minWidth: 250,
  },
  {
    headerName: 'Gender',
    field: 'gender',
    minWidth: 250,
  },
];
export const CLIENT_INFORMATION_COLUMN = [
  {
    headerName: 'TPA Org ID',
    field: 'payerOrgId',
    minWidth: 250,
  },
  {
    headerName: 'TPA Name',
    field: 'payerName',
    minWidth: 250,
  },
  {
    headerName: 'Run Date',
    field: 'runDate',
    minWidth: 250,
  },
  {
    headerName: 'Run Time',
    field: 'runTime',
    minWidth: 250,
  },
];
export const EMPLOYER_GROUP_INFORMATION_COLUMN = [
  {
    headerName: 'Group ID',
    field: 'cignaGroupId',
    minWidth: 100,
  },
  {
    headerName: 'Group Name',
    field: 'groupName',
    minWidth: 150,
  },
  {
    headerName: 'Payer Group ID',
    field: 'tpaGroupId',
    minWidth: 150,
  },
  {
    headerName: 'Internal Group ID',
    field: 'internalGroupId',
    minWidth: 300,
  },
];
export const PRODUCT_INFO_COLUMN = [
  {
    headerName: 'Enterprise ID',
    field: 'enterpriseId',
    sortable: true,
  },
  {
    headerName: 'Product Description',
    field: 'decription',
    sortable: true,
  },
  {
    headerName: 'Effective Date',
    field: 'effectiveDate',
    sortable: true,
    comparator: (valueA, valueB) => {
      const dateA = new Date(valueA);
      const dateB = new Date(valueB);
      return dateA - dateB;
    },
  },
  {
    headerName: 'Termination Date',
    field: 'terminationDate',
    sortable: true,
    unSortIcon: true,
  },
];
export const ERROR_INFO_COLUMN = [
  {
    headerName: 'Error Type',
    field: 'errorType',
    sortable: true,
    unSortIcon: true,
  },
  {
    headerName: 'Error Description',
    field: 'errorDecription',
    sortable: true,
  },
  {
    headerName: 'Status',
    field: 'status',
    sortable: true,
  },
  {
    headerName: 'Assignee ID',
    field: 'assigneeId',
    sortable: true,
  },
  {
    headerName: 'Assignee Name',
    field: 'assigneeName',
    sortable: true,
  },
];
