import { GET_ALL_TABLE } from '../actionTypes';
import API_TYPE from '../apiMethodConstant';
import BODY_TYPE from '../apiBodyDefination';
const commonApiConstants = [
  {
    NAME: GET_ALL_TABLE,
    PATH: 'dashboard/getTableLayout',
    METHOD: API_TYPE.GET,
    AFTER_ACTION: GET_ALL_TABLE,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
];
export default commonApiConstants;
