import React, { useState, useRef, useContext } from "react";
import { connect } from "react-redux";
import '../../../Assets/Css/BasicSearchScreen.css';
import { useNavigate, useLocation } from "react-router-dom";
import {
    Alert,
    AlertTitle,
    Grid,
    Button
} from "@mui/material";
import useSecureNavigate from "../../CustomHooks/useSecureNavigate";
import { emptyObject } from "../../../Utils/Common/common.util"
import { inputAction } from "../../../Redux/Actions/Common/inputAction"
import { membershipSearchAction } from "../../../Redux/Actions/Membership/memberSearchAction"
import { ROUTES } from "../../../Constants/routesConstant";
import { SSN_ALLOWED_KEYS } from "../../../Constants/widgetConstant";

const PayerFileSearch = (props) => {
    const secureNavigate = useSecureNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({ firstName: '', lastName: '', primarySubscriberSsn: '', alternamteMemberId: '' });
    const [errorMsg, setErrorMsg] = useState('');
    const FirstNameRef = useRef(null);
    const LastNameRef = useRef(null);
    const PayerIdRef = useRef(null);
    const SSNRef = useRef(null);
    const AlternateMemberIdRef = useRef(null);
    const SearchButtonRef = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name === 'primarySubscriberSsn'){
            handleSSNInput(e);
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
        setErrorMsg("");
    };
    const handlePayerFileSearch = async (e) => {
        e.preventDefault();
        const { firstName, lastName, primarySubscriberSsn, alternateMemberID_clientAssigned } = formData;
        if (!(lastName || firstName || primarySubscriberSsn || alternateMemberID_clientAssigned)) {
            setErrorMsg("Payer Id and an additional field are mandatory to see file search data.");
            return;
        }
        props.input({ ...props.component_input, 'payerFileSearch': formData })
        if (props?.component_input?.payerFileSearch && !emptyObject(props?.component_input?.payerFileSearch)) {
            secureNavigate(`${ROUTES.FILE_SEARCH}`, { state: { from: location.pathname } });
        }
    };

    const handleTabNavigation = (e, ref) => {
        if (e.key === 'Tab' && ref.current === document.activeElement) {
            e.preventDefault();
            if (ref === PayerIdRef) {
                SearchButtonRef.current.focus();
            } else {
                ref.current.focus();
            }
        }
    };

    const handleSSNInput = (e) => {
        const allowedKeys = SSN_ALLOWED_KEYS;

        if(e.nativeEvent.data && !allowedKeys.includes(e.nativeEvent.data)) {
            e.preventDefault();
            return;
        }

        let value = SSNRef.current.value.replace(/\D/g, '');
        let name = e.target.name;
        if (value.length > 3 && value.length <= 5) {
            value = `${value.slice(0,3)}-${value.slice(3)}`;
        } else if(value.length > 5){
            value = `${value.slice(0,3)}-${value.slice(3, 5)}-${value.slice(5, 9)}`;
        }
        setFormData((prev) => ({ ...prev, [name]: value}));
    };

    return (
        <>
            <div className="BasicSearch">
                <div className="Basicsearchform">
                    <div className="form-group">
                        <p>First name</p>
                        <input
                            type="text"
                            placeholder="Enter first name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            ref={FirstNameRef}
                            onKeyDown={(e) => handleTabNavigation(e, LastNameRef)}
                        />
                    </div>

                    <div className="form-group">
                        <p>Last name</p>
                        <input
                            type="text"
                            placeholder="Enter last name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            ref={LastNameRef}
                            onKeyDown={(e) => handleTabNavigation(e, SSNRef)}
                        />
                    </div>

                    <div className="form-group">
                        <p>Social Security number</p>
                        <input
                            type="text"
                            placeholder="Enter Social Security Number"
                            name="primarySubscriberSsn"
                            value={formData.primarySubscriberSsn}
                            onChange={handleChange}
                            ref={SSNRef}
                            onKeyDown={(e) => {
                                    handleTabNavigation(e, AlternateMemberIdRef);
                                }
                            }
                        />
                    </div>

                    <div className="form-group">
                        <p>Alternate Member ID</p>
                        <input
                            type="text"
                            placeholder="Enter Alternate Member Id"
                            name="alternateMemberID_clientAssigned"
                            value={formData.alternateMemberID_clientAssigned}
                            onChange={handleChange}
                            ref={LastNameRef}
                            maxLength={15}
                            onKeyDown={(e) => {   
                                    handleTabNavigation(e, SearchButtonRef)
                                }
                            }
                        />
                    </div>
                    <Grid item xs={12} className="eligibility-error-btn">
                        <Button 
                        className={`view-all-error`} 
                        variant="contained" color="success" 
                        onClick={handlePayerFileSearch} 
                        ref={SearchButtonRef}>
                            Search
                        </Button>
                    </Grid>
                    {errorMsg && (
                        <div className="pfserrmsg" id="errormsgs">
                            <Alert severity="error"
                                onClose={() => setErrorMsg(null)}>
                                <AlertTitle>Error</AlertTitle>
                                {errorMsg}
                            </Alert>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
};

const mapStateToProps = (state) => {
    return { ...state };
};

const mapActionToProps = {
    input: inputAction,
    getSearchData: membershipSearchAction
};

export default connect(mapStateToProps, mapActionToProps)(PayerFileSearch);