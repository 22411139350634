import React from 'react';
import {
    Modal,
    Box,
    Typography,
    Grid,
    Button
} from '@mui/material';
import Icon from "../../Common/Icon"
import { modalStyle } from "../../../Constants/constants"
import {
    REFRESH_TITLE,
    REFRESH_TEXT,
    REFRESH_BTN_TEXT
} from "../../../Constants/ComponentConstants/MembershipConstants/DetailsConstants/disclaimerModalConstants"
import { connect } from "react-redux";
import { MORE_ACTION } from "../../../Constants/ComponentConstants/componentConstants"
import { inputAction } from "../../../Redux/Actions/Common/inputAction"

const DGCDisclamerModal = (props) => {
    const { useEffect } = React
    const { MENU: { EDIT } } = MORE_ACTION
    const {
        selectedUser,
        component_input,
        input,
        component_input: { dgcDisclaimer = { modalType: EDIT, modalOpen: false } },
    } = props
    const { modalType, modalOpen } = dgcDisclaimer

    const handleClose = () => { input({ ...component_input, 'dgcDisclaimer': { modalOpen: false } }); }

    const handleDGCNavigation = () => {
        let queryStr = ''
        if (modalType === EDIT) {
            const user = { ...selectedUser, action: EDIT }
            queryStr += "?"
            Object.keys(user).forEach(key => {
                queryStr += `${key}=${user[key]}&`
            })
            queryStr = queryStr.replace(/&$/, "");
        }
        const dgcUrlEN = `${process.env.REACT_APP_DGC_URL}/${queryStr}`;
        window.open(dgcUrlEN, '_blank');
    }

    const handleRefresh = () => {
        handleClose()
        setTimeout(() => {
            window.location.href = document.location.href
        }, 100)
    }

    useEffect(()=>{
        if(modalOpen)
            handleDGCNavigation()
    },[modalOpen])

    return (
        <>
            <Modal
                open={modalOpen}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                fullWidth maxWidth="md"
            >
                <Box sx={modalStyle}>
                    <Grid container spacing={2}>
                        <Grid xs={11}>
                            <Typography id="modal-title" variant="h6" component="h6">
                                <b>{REFRESH_TITLE}</b>
                            </Typography>
                        </Grid>
                        <Grid style={{ textAlign: 'right', cursor: 'pointer' }} xs={1}>


                        </Grid>
                    </Grid>
                    <Grid style={{ textAlign: 'center' }} container spacing={2}>
                        <Grid item xs={12}>
                            <p> {REFRESH_TEXT} </p>
                        </Grid>

                        <Grid item xs={11} style={{ textAlign: 'right' }}>
                            <Button onClick={handleRefresh} variant="contained">
                                <Icon name="RefreshIcon" /> {REFRESH_BTN_TEXT}
                            </Button>
                        </Grid>

                    </Grid>
                </Box>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    const { selectedUser, component_input } = state
    return { selectedUser, component_input };
};

const mapActionToProps = {
    input: inputAction
};

export default connect(mapStateToProps, mapActionToProps)(DGCDisclamerModal);