import { FETCHING_DATA, GET_ALL_TABLE } from '../../../Constants/actionTypes';
import APIS from '../../../Constants/apiConstant';
import { apiAction } from '../API/apiAction';
export const getAllTablesAction = () => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === GET_ALL_TABLE),
      },
    });
    await apiAction(dispatch, GET_ALL_TABLE);
  };
};
