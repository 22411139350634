import React from "react";
import Icon from "../../Common/Icon";
import useSecureNavigate from "../../CustomHooks/useSecureNavigate";
import { ROUTES } from '../../../Constants/routesConstant';
import { Button, Grid } from '@mui/material';

const EligibilityMaintenance = (props) => {
    const { links } = props;
    const secureNavigate = useSecureNavigate();

    const handleAddNewSubscriber = () => {
        secureNavigate(ROUTES.ADD_NEW_SUBSCRIBER);
    };

    const handleLinkClick = (url) => {
        secureNavigate(url);
    };

    return (
        <Grid container spacing={2}>
            {links.map((link, index) => (
                <Grid item xs={12} key={link.value || index}>
                    <Button
                        className={`widget-anchor`}
                        variant="text"
                        style={{ cursor: 'pointer',color: 'rgb(3, 92, 103)' }}
                        onClick={handleAddNewSubscriber}
                        

                    >
                        {link.text}
                        <Icon name="ArrowOutwardIcon" style={{ cursor: 'pointer' }} />
                    </Button>
                </Grid>
            ))}

        </Grid>
    );
}

export default EligibilityMaintenance;