import { useNavigate } from "react-router-dom";
import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND;
const IS_LOCAL = process.env.REACT_APP_ENV === 'local';

const useSecureNavigate = () => {
    const navigate = useNavigate();

    const secureNavigate = async (path) => {
        try {
            if (IS_LOCAL) {
                navigate(path)
            }
            else {
                const response = await axios.get(`${BACKEND_URL}/authenticate`, {
                    withCredentials: true,
                });

                if (response.status === 200) {
                    navigate(path);
                } else {
                    window.location.href = `${BACKEND_URL}/loginInitiate`
                }
            }
        }
        catch (error) {
            console.error('Authentication check failed: ', error);
            window.location.href = `${BACKEND_URL}/loginInitiate`
        }
    }

    return secureNavigate;
}

export default useSecureNavigate;