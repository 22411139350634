import { GRID_SAVING_GET } from '../../../Constants/actionTypes';

export const aggridSavingRed = (state = null, action) => {
  switch (action.type) {
    case GRID_SAVING_GET:
      return action.payload;
    default:
      return state;
  }
};
