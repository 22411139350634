import React, { useState, useMemo } from 'react';
import { Box, Button, FormControl, InputLabel, Select, MenuItem, Typography, TextField } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const AddNewSubscriber = () => {
    const [selectedPayer, setSelectedPayer] = useState({ value: '', text: '' });
    const [selectedOption1, setSelectedOption1] = useState({ value: '', text: '' });
    const [selectedOption2, setSelectedOption2] = useState({ value: '', text: '' });
    const [searchPayer, setSearchPayer] = useState('');
    const [searchOption1, setSearchOption1] = useState('');
    const [searchOption2, setSearchOption2] = useState('');
    const [rowData, setRowData] = useState([]);
    const [isAddButtonEnabled, setIsAddButtonEnabled] = useState(false);

    const payerOptions = Array.from({ length: 20 }, (_, i) => ({
        text: `Payer Org ID ${i + 1} - Payer Name ${i + 1}`,
        value: `payer${i + 1}`
    }));

    const cignaGroupIdOptions = {
        payer1: Array.from({ length: 20 }, (_, i) => ({
            text: `Cigna Group ID ${i + 1} - Group Name ${i + 1}`,
            value: `cigna${i + 1}`
        })),
        payer2: Array.from({ length: 20 }, (_, i) => ({
            text: `Cigna Group ID ${i + 21} - Group Name ${i + 21}`,
            value: `cigna${i + 21}`
        })),
        payer3: Array.from({ length: 20 }, (_, i) => ({
            text: `Cigna Group ID ${i + 41} - Group Name ${i + 41}`,
            value: `cigna${i + 41}`
        })),
    };

    const groupOrgIdOptions = {
        cigna1: Array.from({ length: 20 }, (_, i) => ({
            text: `Group Org ID ${i + 1} - Group Org Name ${i + 1}`,
            value: `group${i + 1}`
        })),
        cigna2: Array.from({ length: 20 }, (_, i) => ({
            text: `Group Org ID ${i + 21} - Group Org Name ${i + 21}`,
            value: `group${i + 21}`
        })),
        cigna3: Array.from({ length: 20 }, (_, i) => ({
            text: `Group Org ID ${i + 41} - Group Org Name ${i + 41}`,
            value: `group${i + 41}`
        })),
        cigna4: Array.from({ length: 20 }, (_, i) => ({
            text: `Group Org ID ${i + 61} - Group Org Name ${i + 61}`,
            value: `group${i + 61}`
        })),
        cigna5: Array.from({ length: 20 }, (_, i) => ({
            text: `Group Org ID ${i + 81} - Group Org Name ${i + 81}`,
            value: `group${i + 81}`
        })),
        cigna6: Array.from({ length: 20 }, (_, i) => ({
            text: `Group Org ID ${i + 101} - Group Org Name ${i + 101}`,
            value: `group${i + 101}`
        })),
    };

    const handlePayerChange = (event) => {
        const selected = payerOptions.find(payer => payer.value === event.target.value);
        setSelectedPayer(selected || { value: '', text: '' });
        setSelectedOption1({ value: '', text: '' });
        setSelectedOption2({ value: '', text: '' });
        setSearchOption1('');
        setSearchOption2('');
        setIsAddButtonEnabled(false);
    };

    const handleOption1Change = (event) => {
        const selected = cignaGroupIdOptions[selectedPayer.value].find(cigna => cigna.value === event.target.value);
        setSelectedOption1(selected || { value: '', text: '' });
        setSearchOption2('');
        if (selected && selectedPayer.value) {
            const newRow = {
                payer: selectedPayer.text,
                cignaGroupId: selected.text,
                groupOrgId: selectedOption2.text,
            };
            if (selectedOption2.value) {
                setRowData(prevData => [...prevData, newRow]);
                setIsAddButtonEnabled(true);
            }
        }
    };

    const handleOption2Change = (event) => {
        const selected = groupOrgIdOptions[selectedOption1.value].find(group => group.value === event.target.value);
        setSelectedOption2(selected || { value: '', text: '' });


        if (selected && selectedOption1.value && selectedPayer.value) {
            const newRow = {
                payer: selectedPayer.text,
                cignaGroupId: selectedOption1.text,
                groupOrgId: selected.text,
            };
            setRowData(prevData => [...prevData, newRow]);
            setIsAddButtonEnabled(true);
        }
    };

    const handleAddClick = () => {

        const dgcUrlEN = process.env.REACT_APP_DGC_URL;
        window.open(dgcUrlEN, '_blank');
    };

    const highlightText = (text, search) => {
        const parts = text.split(new RegExp(`(${search})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === search.toLowerCase() ? <strong key={index}>{part}</strong> : part
        );
    };
    const columnDefs = useMemo(() => [
        { headerName: "Payer Org ID", field: "payer" },
        { headerName: "Cigna Group ID", field: "cignaGroupId" },
        { headerName: "Group Org ID", field: "groupOrgId" },
    ], []);

    return (
        <div>
            <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                <h1 className="searchtitle">Add New Subscriber</h1>
            </Box>
            <Box sx={{ marginTop: 2 }}>
                <div className="line" style={{ borderBottom: '1px solid #ccc', marginBottom: '16px' }}></div>
            </Box>
            <Box display="flex" alignItems="center" sx={{ marginBottom: 2, marginTop: 2, }}>
                <Box display="flex" justifyContent="space-between" sx={{ marginLeft: 2 }}>
                    <Box sx={{ minWidth: 120, marginRight: 2, marginLeft: 3 }}>
                        <FormControl fullWidth>
                            <InputLabel id="payer-select-label">Payer Org ID</InputLabel>
                            <Select
                                labelId="payer-select-label"
                                id="payer-select"
                                value={selectedPayer.value}
                                label="Payer Org ID"
                                onChange={handlePayerChange}
                                size="small"
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 150,
                                            width: 'auto',
                                            overflowY: 'auto',
                                        },
                                    },
                                }}
                            >
                                <TextField
                                    placeholder="Search..."
                                    value={searchPayer}
                                    onChange={(e) => setSearchPayer(e.target.value)}
                                    size="small"
                                    sx={{ marginBottom: 1 }}
                                />
                                {payerOptions
                                    .filter(payer => payer.text.toLowerCase().includes(searchPayer.toLowerCase()))
                                    .map((payer) => (
                                        <MenuItem key={payer.value} value={payer.value}>
                                            {highlightText(payer.text, searchPayer)}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 120, marginRight: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel id="option1-select-label">Cigna Group ID</InputLabel>
                            <Select
                                labelId="option1-select-label"
                                id="option1-select"
                                value={selectedOption1.value}
                                label="Cigna Group ID"
                                onChange={handleOption1Change}
                                size="small"
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 150,
                                            width: 'auto',
                                            overflowY: 'auto',
                                        },
                                    },
                                }}
                                disabled={!selectedPayer.value}
                            >
                                <TextField
                                    placeholder="Search..."
                                    value={searchOption1}
                                    onChange={(e) => setSearchOption1(e.target.value)}
                                    size="small"
                                    sx={{ marginBottom: 1 }}
                                />
                                {selectedPayer.value && cignaGroupIdOptions[selectedPayer.value]
                                    .filter(cigna => cigna.text.toLowerCase().includes(searchOption1.toLowerCase()))
                                    .map((cigna) => (
                                        <MenuItem key={cigna.value} value={cigna.value}>
                                            {highlightText(cigna.text, searchOption1)}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="option2-select-label">Group Org ID</InputLabel>
                            <Select
                                labelId="option2-select-label"
                                id="option2-select"
                                value={selectedOption2.value}
                                label="Group Org ID"
                                onChange={handleOption2Change}
                                size="small"
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 150,
                                            width: 'auto',
                                            overflowY: 'auto',
                                        },
                                    },
                                }}
                                disabled={!selectedOption1.value}
                            >
                                <TextField
                                    placeholder="Search..."
                                    value={searchOption2}
                                    onChange={(e) => setSearchOption2(e.target.value)}
                                    size="small"
                                    sx={{ marginBottom: 1 }}
                                />
                                {selectedOption1.value && groupOrgIdOptions[selectedOption1.value]
                                    .filter(group => group.text.toLowerCase().includes(searchOption2.toLowerCase()))
                                    .map((group) => (
                                        <MenuItem key={group.value} value={group.value}>
                                            {highlightText(group.text, searchOption2)}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ marginLeft: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddClick}
                            disabled={!selectedPayer.value || !selectedOption1.value || !selectedOption2.value} // Disable until all selections are made
                        >
                            ADD
</Button>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ marginTop: 2 }}>
                <div className="ag-theme-alpine" style={{ minHeight: '200px', width: '100%', maxWidth: '1200px', margin: '0 auto', padding: '10px' }}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        pagination={true}
                        paginationPageSize={10}
                        domLayout='autoHeight'
                    />
                </div>
            </Box>
            <Box sx={{ height: '60px', backgroundColor: '#f5f5f5', marginTop: 2 }}>
                <Typography variant="body2" align="center" sx={{ padding: 1 }}>
                </Typography>
            </Box>
        </div>
    );
};

export default AddNewSubscriber;