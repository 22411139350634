const TITLE = 'Disclaimer';
const CONTENT = 'You are being redirected to the DGC screen';
const CONFIRM_TEXT =
  'Please confirm to proceed or cancel if you do not wish to continue?';
const CANCEL_BTN_TEXT = 'CANCEL';
const CONFIRM_BTN_TEXT = 'Confirm';

const REFRESH_TITLE = 'Refresh';
const REFRESH_TEXT =
  'You redirected to DGC so you need to refresh to get latest data';
const REFRESH_BTN_TEXT = 'Refresh';

export {
  TITLE,
  CONTENT,
  CONFIRM_TEXT,
  CANCEL_BTN_TEXT,
  CONFIRM_BTN_TEXT,
  REFRESH_TITLE,
  REFRESH_TEXT,
  REFRESH_BTN_TEXT,
};
