import {
  FETCHING_DATA,
  ASSIGNED_ERRORS_PROGRESS,
} from '../../../Constants/actionTypes';
import APIS from '../../../Constants/apiConstant';
import { apiAction } from '../API/apiAction';

export const assignedErrorsProgessAction = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === ASSIGNED_ERRORS_PROGRESS),
      },
    });
    await apiAction(dispatch, ASSIGNED_ERRORS_PROGRESS, payload);
  };
};
