import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import '../../../Assets/Css/FileTracking.css'
import {
  fileTrackingColumnDef,
  tableColumnOrder,
  createPayload
} from "../../../Utils/Widget/PayerEligibilityFileTracking/fileTracking.util"
import { fileTrackingAction } from "../../../Redux/Actions/Widgets/fileTrackingAction"
import { aggridSavingAction } from "../../../Redux/Actions/Membership/aggridSavingAction"
import { getAllTablesAction } from "../../../Redux/Actions/Common/getAllTablesAction"
import { connect } from "react-redux";
import { ROUTES } from "../../../Constants/routesConstant"
import { useNavigate, useLocation } from "react-router-dom";
import {
  TITLE,
  CLASS_NAME,
  PAGINATION,
  TEXT
} from "../../../Constants/ComponentConstants/WidgetConstants/PayerEligibilityFileTrackingConstants/fileTrackingConstants"
import { COMMON_CLASS, COMMON_TEXT, AG_COLUMN_CENTER } from "../../../Constants/constants"
import { ICON_CONSTANT } from "../../../Constants/iconsConstant"
import Icon from "../../Common/Icon"
import "../../../Assets/Css/Breadcrumbs.css"
import IconButton from '@mui/material/IconButton';
import { Box, Typography, Grid } from '@mui/material';
import FileStatusRender from "./FileStatusRender"
import GotoFileDetails from "./GotoFileDetails"
import FileTrackingDatePicker from "./FileTrackingDatePicker"
import dayjs from 'dayjs';
import {getMonth} from "../../../Utils/Common/common.util"
import useSecureNavigate from '../../CustomHooks/useSecureNavigate';

const Filetracking = (props) => {
  const { useState, useMemo, useEffect, useRef } = React
  const secureNavigate = useSecureNavigate();
  const location = useLocation();
  const { DOWNLOAD_EXCEL } = TEXT
  const [selectMonth, setSelectMonth] = useState(dayjs());
  const [columnDefs, setColumnDefs] = useState([])
  const [savedPayload, setSavedPayload] = useState({})
  const { getData, file_tracking: { records = [] }, get_all_table, saveAggrid, getAllTables } = props
  const {
    SEARCH_TITLE,
    LINE,
    FILE_TRACKING_GRID_CLASS,
    DATE_PICKER_CONTAINER,
    DOWNLOAD_CONTAINER,
    FILE_TRACKING_RESULT
  } = CLASS_NAME
  const { AG_THEME_ALPINE, CURSOR_POINTER } = COMMON_CLASS
  const { CUSTOM } = COMMON_TEXT
  const { FILE_SEARCH } = ROUTES
  const { FILE_TRACKING } = TITLE
  const { INSERT_DRIVE_FILE_OUTLINED_ICON } = ICON_CONSTANT
  const { state: { componentName = '', payerEligibilityTableId = '' } } = location

  const gridApiRef = useRef(null);
  const gridRef = useRef(null);

  useEffect(() => {
    return () => {
      getAllTables()
    };
  }, [])

  useEffect(() => {
    getData(
      {
        month: selectMonth.month() + 1,
        year: selectMonth.year(),
        page: 1,
        limit: 100
      }
    )
  }, [selectMonth])

  useEffect(() => {
    const { table = [], layOut = {} } = tableColumnOrder({
      get_all_table,
      componentName,
      payerEligibilityTableId
    },
      fileTrackingColumnDef({
        AG_COLUMN_CENTER,
        FileStatusRender,
        GotoFileDetails,
        records,
        goToFile,
        INSERT_DRIVE_FILE_OUTLINED_ICON,
        CURSOR_POINTER
      }))
    setColumnDefs(table)
    setSavedPayload(layOut)
  }, [get_all_table])

  const goToFile = (param = '') => {
    secureNavigate(FILE_SEARCH, { state: { from: location?.pathname, fileName: param } })
  }
  const onColumnMoved = async (params) => {
    gridRef.current = params.api
    if (gridRef.current) {
      const allColumnState = gridRef.current.getColumnState();
      const payload = createPayload({ columnDefs, savedPayload, allColumnState, componentName, payerEligibilityTableId })
      await saveAggrid(payload)
    }
  }



  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      flex: 1,
      minWidth: 120,
      display: "flex",
      alignItems: "center",
      textAlign: "center"
    };
  }, []);
  const rowSelection = useMemo(() => {
    return {
      mode: "multiRow",
      groupSelects: "descendants",
      headerCheckbox: false,
      checkboxes: false,
    };
  }, []);
  const exportToExcel = () => {
    gridRef.current.api.exportDataAsExcel({
      fileName: 'FileTrackingData.xlsx',
    });
  };
  return (
    <>
      <h1 className={`${SEARCH_TITLE}`}>{FILE_TRACKING}</h1>
      <div className={`${LINE}`}></div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <FileTrackingDatePicker
              value={selectMonth}
              getValue={setSelectMonth}
              label={`Select Month`}
              minDate={dayjs().subtract(1, 'year')}
              views={['month', 'year']}
              containerClass={DATE_PICKER_CONTAINER} />
            <p className={`${FILE_TRACKING_RESULT}`}>
              {`Showing 
              ${location?.state?.payerName ?
                  records.filter(record => location?.state?.payerName === record.payerName)?.length :
                  records?.length} for ${getMonth(selectMonth.month())}, ${selectMonth.year()}
              `}
            </p>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={exportToExcel}
              className={`${DOWNLOAD_CONTAINER}`}
              sx={
                {
                  color: 'blue',
                  fontSize: 15,
                  border: 'none',
                  outline: 'none',
                  background: 'none',
                  borderRadius: 0
                }
              }>
              <Typography variant="body2" sx={{ margin: 0 }}>{DOWNLOAD_EXCEL}</Typography>
              <Icon name="ArrowDownwardIcon" />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <div className={`${AG_THEME_ALPINE} ${FILE_TRACKING_GRID_CLASS}`}>
        <AgGridReact
          rowData={location?.state?.payerName ? records.filter(record => location?.state?.payerName === record.payerName) : records}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          groupDisplayType={CUSTOM}
          groupDefaultExpanded={0}
          rowSelection={rowSelection}
          pagination={true}
          paginationPageSize={10}
          enableColumnHandling
          ref={(grid) => { gridApiRef.current = grid }}
          onColumnMoved={onColumnMoved}
          onSortChanged={onColumnMoved}
          paginationPageSizeSelector={PAGINATION}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { file_tracking, get_all_table } = state
  return { file_tracking, get_all_table };
};

const mapActionToProps = {
  getData: fileTrackingAction,
  saveAggrid: aggridSavingAction,
  getAllTables: getAllTablesAction
};

export default connect(mapStateToProps, mapActionToProps)(Filetracking);
