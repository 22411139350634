import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom"
import { AgGridReact } from "ag-grid-react";
import Select from "react-select";
import {
    FormControl
} from '@mui/material';
import { Button } from '@mui/material';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { store } from '../../../Redux/store';

const DropdownWithMultiselect = (props) => {
    const [isEditable, setIsEditable] = useState(false);
    const [tooltipContent, setTooltipContent] = useState(null);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipTarget, setTooltipTarget] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const { admin_user = [], saveAdminUsers, getAdminUsers } = props;
    const [rowData, setRowData] = useState([]);
    const [options, setOptions] = useState([]);
    const gridRef = useRef(null);

    useEffect(() => {
        let container = document.getElementById("tooltip-container");
        if (!container) {
            container = document.createElement("div");
            container.id = "tooltip-container";
            document.body.appendChild(container)
        }
        setTooltipTarget(container);
    }, []);


    const fetchData = async () => {
        try {
            const token = store?.getState()?.okta_token?.accessToken;
            const payerIds = await axios.get(`${process.env.REACT_APP_BASE_URL_QA}/v1/admin/getPayerOrgIds`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            
            const payerMapping = payerIds?.data?.map((payer) => {
                return {
                    value: payer?.payerOrgId,
                    label: payer?.payerName
                }
            }) || [];
            // get_tpa_ids
            setOptions(payerMapping);
        } catch(err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const onGridReady = (params) => {
        gridRef.current = params.api;
    }

    useEffect(() => {
        if (!admin_user?.error) {
            const securityData = (admin_user || [])?.map(user => {
                const { userId, name, selectAll, whitelist, blacklist } = user;
                return { id: uuidv4(), user: userId, name: name, selectAll, whitelist: !selectAll ? whitelist?.filter(ds => Number(ds.payerOrgId) !== 9999999) : [{ payerOrgId: "9999999", payerName: "All Payers" }], blacklist }
            })
            setRowData([...[], ...securityData]);
            setIsEditable(false)
        }
    }, [admin_user])

    const handleCheckboxChange = (params = {}) => {
        const { data: { id } } = params
        const adminUser = admin_user.filter((user) => user.userId === params?.data?.user)[0]
        setRowData((prevRowData) =>
            prevRowData.map((row) => {
                return row.id === id ? { ...row, whitelist: !row.selectAll ? [{ payerOrgId: "9999999", payerName: "All Payers" }] : adminUser.whitelist?.filter(ds => Number(ds.payerOrgId) !== 9999999), blacklist: row.selectAll ? [] : adminUser.blacklist, selectAll: !row.selectAll } : row
            })
        );
    };

    const handleSelectChange = (id, type, selectedOptions) => {
        const updatedOptions = selectedOptions ? selectedOptions.map(option => {
            const parts = option.label.split(" - ");
            return {
                payerOrgId: option.value,
                payerName: parts.length > 1 ? parts[1] : option.label // Ensure to handle cases where the split might not yield expected results
            };
        }) : [];

        setRowData((prevRowData) =>
            prevRowData.map((row) =>
                row.id === id ? { ...row, [type]: updatedOptions } : row
            )
        );
    }

    const handleEditableButtonClick = async (event) => {
        event.preventDefault();
        if (!isEditable) {
            setIsEditable(true)
        } else {
            setIsEditable(true);
            const saveUsers = rowData.map(data => {
                return {
                    userId: data.user,
                    selectAll: data.selectAll,
                    whitelist: data.whitelist,
                    blacklist: data.blacklist
                }
            });
            if (saveUsers.length > 0) {
                await saveAdminUsers(saveUsers)
                await getAdminUsers()
            }
        }
    }

    const gridOptions = {
        getRowId: params => params.data.user,
        // rowHeight: 60
    };

    const columnDefs = [
        {
            headerName: "User",
            field: 'user',
            editable: false,
            minWidth: 200,
            maxWidth: 200
        },
        {
            headerName: "Name",
            field: 'name',
            editable: false,
            minWidth: 300,
            maxWidth: 300
        },
        {
            headerName: "Select All Payers",
            field: "selectAll",
            cellRenderer: (params) => (
                <>
                    {
                        isEditable ? <input
                            type="checkbox"
                            checked={params?.data?.selectAll}
                            onChange={() => handleCheckboxChange(params)}
                        /> : <input
                                disabled
                                type="checkbox"
                                checked={params?.data?.selectAll}
                                onChange={() => handleCheckboxChange(params)}
                            />
                    }
                </>

            ),
            minWidth: 200,
            maxWidth: 200
        },
        {
            headerName: "Whitelisted Payers",
            field: "whitelistedPayers",
            minWidth: 500,
            cellRenderer: (params) => {
                const dropdownRef = useRef(null);

                const handleMouseEnter = () => {
                    const selectedItems = params?.data?.whitelist || [];
                    if (selectedItems.length > 0) {
                        setTooltipContent(selectedItems.map((option) => (
                            <li key={option?.payerOrgId}>
                                {option?.payerOrgId} - {option?.payerName}
                            </li>
                        )))
                        if (dropdownRef.current) {
                            const rect = dropdownRef.current.getBoundingClientRect();
                            const marginTopY = selectedItems.length === 1 ? 0 : 50;
                            setTooltipPosition({
                                top: rect.bottom + window.scrollY - marginTopY,
                                left: rect.left + window.scrollX
                            })
                        }
                        setTooltipVisible(true)
                    }
                };

                const handleMouseLeave = () => {
                    setTooltipVisible(false)
                };

                return (
                    <FormControl sx={{ m: 1, width: 600 }} size="small" FullWidth>

                        {isEditable ? <Select
                            isMulti={true}
                            isDisabled={params?.data?.selectAll}
                            options={options.filter((obj1) => {
                                return !params?.data?.whitelist.some((obj2) => Number(obj1.value) === Number(obj2.value))
                            })}
                            value={params?.data?.whitelist.map(item => ({
                                value: item.payerOrgId || "", 
                                label: item.payerOrgId === "9999999" ? "All Payers" : `${item.payerOrgId} - ${item.payerName || ""}` // Show "All Payers" for the specific ID
                            }))}
                            onChange={(selectedOptions) => {
                                handleSelectChange(params?.data?.id, "whitelist", selectedOptions)
                            }}
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9998 })
                            }}
                        />
                            : (
                            <div
                                ref={dropdownRef}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <Select
                                    isMulti={true}
                                    isDisabled={true}
                                    options={options.filter((obj1) => {
                                        return !params?.data?.whitelist.some((obj2) => Number(obj1.value) === Number(obj2.value))
                                    })}
                                        value={params?.data?.whitelist.map(item => ({
                                            value: item.payerOrgId || "", 
                                            label: item.payerOrgId === "9999999" ? "All Payers" : `${item.payerOrgId} - ${item.payerName || ""}` // Show "All Payers" for the specific ID
                                        }))}
                                        onChange={(selectedOptions) =>
                                            handleSelectChange(params?.data?.id, "whitelist", selectedOptions)
                                        }
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9998 })
                                    }}
                                /></div>)}
                    </FormControl>
                )
            }
        },
        {
            headerName: "Blacklisted Payers",
            field: "blacklistedPayers",
            minWidth: 500,
            cellRenderer: (params) => {
                const dropdownRef = useRef(null);

                const handleMouseEnter = () => {
                    const selectedItems = params?.data?.blacklist || [];
                    if (selectedItems.length > 0) {
                        setTooltipContent(selectedItems.map((option) => (
                            <li key={option?.payerOrgId}>
                                 {option?.payerName}
                            </li>
                        )))
                        if (dropdownRef.current) {
                            const rect = dropdownRef.current.getBoundingClientRect();
                            const marginTopY = selectedItems.length === 1 ? 0 : 20;

                            setTooltipPosition({
                                top: rect.bottom + window.scrollY - marginTopY,
                                left: rect.left + window.scrollX
                            })
                        }
                        setTooltipVisible(true)
                    }
                };

                const handleMouseLeave = () => {
                    setTooltipVisible(false)
                };
                return (<FormControl sx={{ m: 1, width: 500 }} size="small" FullWidth>

                    {isEditable ? <Select
                        isMulti={true}
                        isDisabled={!params?.data?.selectAll}
                        options={options.filter((obj1) => {
                            return !params?.data?.blacklist.some((obj2) => Number(obj1.value) === Number(obj2.value))
                        })}
                        value={params?.data?.blacklist.map(item => ({
                            value: item.payerOrgId || "",
                            label: item.payerName || ""
                        }))}
                        onChange={(selectedOptions) =>
                            handleSelectChange(params?.data?.id, "blacklist", selectedOptions)
                        }
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9998 })
                        }}
                    />
                        : (
                        <div
                            ref={dropdownRef}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Select
                                isMulti={true}
                                isDisabled={true}
                                options={options.filter((obj1) => {
                                    return !params?.data?.blacklist.some((obj2) => Number(obj1.value) === Number(obj2.value))
                                })}
                                value={params?.data?.blacklist.map(item => ({
                                        value: item.payerOrgId || "",
                                        label: item.payerName || ""
                                    }))}
                                onChange={(selectedOptions) =>
                                    handleSelectChange(params?.data?.id, "blacklist", selectedOptions)
                                }
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9998 })
                                }}
                            /></div>)}

                </FormControl>)
            }
        },
    ];

    return (
        <div>
            <div style={{ display: 'flex', width: "90vw", justifyContent: 'right', marginBottom: '10px' }}>
                <Button variant="outlined" onClick={handleEditableButtonClick}>
                    {isEditable ? 'Save Settings' : 'Edit Settings'}
                </Button>
            </div>
            <div
                className={
                    "ag-theme-quartz"
                }
                style={{ width: '90vw', height: '400px' }}
            >

                <AgGridReact
                    onGridReady={onGridReady}
                    gridOptions={gridOptions}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={{
                        flex: 1,
                        width: 100,                        
                    }}
                    rowHeight={70}
                    tooltipHideDelay={true}
                    cellStyle={{outline: 'none', fontSize: '10px'}}
                    pagination={true}
                    paginationPageSize={5}
                />

            </div>
            {tooltipVisible && tooltipTarget && !isEditable &&
                (ReactDOM.createPortal(
                    <div
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: tooltipPosition.top,
                            left: tooltipPosition.left,
                            backgroundColor: "#fff",
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            borderShadow: "0 4px 6px rgba(0,0,0,0.1)",
                            zIndex: 9999,
                        }}
                    >
                        {tooltipContent || "No Options Selected"}
                    </div>,
                    tooltipTarget)
                )}
        </div>
    )
}

export default DropdownWithMultiselect;