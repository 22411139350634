import { FETCHING_DATA, ASSIGNEE_API } from '../../../Constants/actionTypes';
import APIS from '../../../Constants/apiConstant';
import { apiAction } from '../API/apiAction';

export const assigneeApiAction = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === ASSIGNEE_API),
      },
    });

    await apiAction(dispatch, ASSIGNEE_API, payload);
  };
};
